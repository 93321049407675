import Head from 'next/head'
import { Provider } from 'react-redux'
import { SnackbarProvider } from 'notistack'
import { UserProvider } from '@auth0/nextjs-auth0'
import { APP_NAME } from '../consts/StringConst'
import AuthorizedApolloProvider from '../utils/AuthorizedApolloProvider'
import store from '../stores'
import type { AppProps } from 'next/app'
import AppThemeProvider from 'app/AppThemeProvider'

export default function App({ Component, pageProps }: AppProps) {
    return (
        <Provider store={store}>
            <Head>
                <meta charSet="utf-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta
                    name="viewport"
                    content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no, viewport-fit=cover"
                />
                <title>{APP_NAME}</title>
                {/*<link href="/icons/icon-16x16.png" rel="icon" type="image/png" sizes="16x16" />*/}
                {/*<link href="/icons/icon-32x32.png" rel="icon" type="image/png" sizes="32x32" />*/}
                {/*<link rel="apple-touch-icon" href="/apple-icon.png"></link>*/}
                {/*<meta name="theme-color" content="#317EFB" />*/}
                {/*<link*/}
                {/*    rel="stylesheet"*/}
                {/*    href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"*/}
                {/*/>*/}
            </Head>
            <UserProvider>
                <AuthorizedApolloProvider>
                    <AppThemeProvider>
                        <SnackbarProvider maxSnack={3}>
                            <Component {...pageProps} />
                        </SnackbarProvider>
                    </AppThemeProvider>
                </AuthorizedApolloProvider>
            </UserProvider>
        </Provider>
    )
}
